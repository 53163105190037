import { Link, graphql } from 'gatsby';

import Checkout from '../components/checkout/Checkout';
import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';

export default ({ data, location }) => {
	if (location.state) {
		const selectedPackage = data.allPackages.edges.filter(
			(node) => node.node.packageid === location.state.selectedPackageId
		)[0].node;

		return (
			<>
				<Layout>
					<SEO title="Чекаут" />
					<Checkout
						selectedPackage={selectedPackage}
						packageTvCount={location.state.packageTvCount}
					/>
				</Layout>
			</>
		);
	} else {
		return (
			<>
				<Layout>
					<SEO title="Чекаут" />
					<div
						className="container text-center mb-5 d-flex justify-content-center align-items-center flex-column"
						style={{ paddingTop: '16rem', minHeight: '50rem' }}
					>
						<h1>Оопс, не сте избрали пакет!</h1>
						<Link to="/za-doma/" className="btn btn-primary green mt-3">
							Обратно
						</Link>
					</div>
				</Layout>
			</>
		);
	}
};

export const query = graphql`
	query CheckoutParent {
		allPackages: allGoogleDevonlySheet {
			edges {
				node {
					id
					packageid: packageId
					speed
					package
					tvcount: tvCount
					price
					internalname: internalName
					informationButton
					timeshift: timeShift
					archive
					record
				}
			}
		}
	}
`;
